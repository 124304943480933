@tailwind base;
@tailwind components;
@tailwind utilities;
@layer components {
  /* This won't be included in your compiled CSS unless you actually use it */
  /*.from-left, .from-right {*/
  /*  opacity: 0;*/
  /*  transform: translateX(-100%);*/
  /*  transition: opacity 2s ease, transform 2s ease;*/
  /*}*/

  /*.from-top, .from-bottom {*/
  /*  opacity: 0;*/
  /*  transition: opacity 2s ease, transform 2s ease;*/
  /*}*/

  /*.from-top {*/
  /*  transform: translateY(-100%);*/
  /*}*/

  /*.from-bottom {*/
  /*  transform: translateY(100%);*/
  /*}*/

  /*.from-right {*/
  /*  transform: translateX(100%);*/
  /*}*/

  /*.from-left {*/
  /*  transform: translateX(-100%);*/
  /*}*/

  /*.from-top.animate, .from-bottom.animate {*/
  /*  opacity: 1;*/
  /*  transform: translateY(0);*/
  /*}*/

  /*.from-left.animate, .from-right.animate {*/
  /*  opacity: 1;*/
  /*  transform: translateX(0);*/
  /*}*/
    html {
        scroll-behavior: smooth;
    }
    .toTheLeft {
        margin-left: calc(448px - 50vw);
    }
    .toTheLeftWithMargin {
        margin-left: calc(488px - 50vw);
    }

    .toTheRight {
        right: calc(448px - 50vw);
    }


}

